var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("goal-tags-filter-panel"),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-lg-8" },
          [_c("goal-create-panel", { attrs: { "allow-filter": false } })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-lg-4" },
          [
            _c(
              "btn-group",
              {
                staticClass: "completion-state-filter-panel",
                attrs: { justified: "" },
              },
              [
                _c(
                  "btn",
                  {
                    staticClass: "filter-button",
                    class: {
                      "filter-button_active":
                        _vm.activeCompletionFilter ===
                        _vm.COMPLETION_FILTER_OPTIONS.TO_DO,
                    },
                    attrs: { justified: "" },
                    on: {
                      click: function ($event) {
                        return _vm.setActiveCompletionFilter(
                          _vm.COMPLETION_FILTER_OPTIONS.TO_DO
                        )
                      },
                    },
                  },
                  [
                    _c("sliding-counter", {
                      attrs: {
                        prefix: "To Do (",
                        postfix: ")",
                        count:
                          _vm.tasksCompletionStatsRelevantCounter.total -
                          _vm.tasksCompletionStatsRelevantCounter.completed,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "btn",
                  {
                    staticClass: "filter-button filter-button__middle",
                    class: {
                      "filter-button_active":
                        _vm.activeCompletionFilter ===
                        _vm.COMPLETION_FILTER_OPTIONS.COMPLETED,
                    },
                    attrs: { justified: "" },
                    on: {
                      click: function ($event) {
                        return _vm.setActiveCompletionFilter(
                          _vm.COMPLETION_FILTER_OPTIONS.COMPLETED
                        )
                      },
                    },
                  },
                  [
                    _c("sliding-counter", {
                      attrs: {
                        prefix: "Completed (",
                        postfix: ")",
                        count:
                          _vm.tasksCompletionStatsRelevantCounter.completed,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "btn",
                  {
                    staticClass: "filter-button",
                    class: {
                      "filter-button_active":
                        _vm.activeCompletionFilter ===
                        _vm.COMPLETION_FILTER_OPTIONS.ALL,
                    },
                    attrs: { justified: "" },
                    on: {
                      click: function ($event) {
                        return _vm.setActiveCompletionFilter(
                          _vm.COMPLETION_FILTER_OPTIONS.ALL
                        )
                      },
                    },
                  },
                  [
                    _c("sliding-counter", {
                      attrs: {
                        prefix: "All (",
                        postfix: ")",
                        count: _vm.tasksCompletionStatsRelevantCounter.total,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "goal-tasks-list" },
        [
          _vm._l(_vm.visibleTasks, function (task) {
            return _c("goal-task", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isTaskFilteredByTags(task),
                  expression: "isTaskFilteredByTags(task)",
                },
              ],
              key: task.id + "" + (task.children ? task.children.length : ""),
              attrs: {
                "initial-task": task,
                layout: _vm.goalTaskLayout,
                "is-completed-mark-editable": _vm.isTaskCompletedMarkEditable,
                "is-editable": _vm.isTaskEditable(task),
                "is-assignee-editable": _vm.isTaskAssigneeEditable(task),
                "allow-multiple-assign": _vm.isAdmin && !_vm.filterIsActive,
                "allow-drag": _vm.isAdmin && !_vm.filterIsActive,
                "delete-forbidden": _vm.filterIsActive,
                "without-children": _vm.withoutChildren,
                "is-admin": _vm.isAdmin,
                "edit-mode": _vm.isTaskInEditMode(task),
                "tags-in-project": _vm.tagsInProject,
                "hide-completed-children":
                  _vm.activeCompletionFilter ===
                  _vm.COMPLETION_FILTER_OPTIONS.TO_DO,
                "hide-in-completed-children":
                  _vm.activeCompletionFilter ===
                  _vm.COMPLETION_FILTER_OPTIONS.COMPLETED,
                availableCompletionOptions: _vm.availableCompletionOptions,
                goal: _vm.goal,
                "router-navigation": true,
              },
            })
          }),
          _vm._v(" "),
          !_vm.visibleTasks.length
            ? _c("div", { staticClass: "alert alert-info" }, [
                _vm._v("There has been nothing added yet"),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.visibleTasks.length < _vm.flattenedTasks.length &&
      !_vm.isAdmin &&
      !_vm.showOtherUsersTasks &&
      !_vm.assigneesInFilter.length
        ? _c("div", {}, [
            _c(
              "strong",
              {
                staticClass: "pointer",
                on: { click: _vm.expandOtherUsersTasks },
              },
              [_vm._v("Show Tasks assigned to other users.")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showOtherUsersTasks && !_vm.assigneesInFilter.length
        ? _c("div", {}, [
            _c(
              "strong",
              {
                staticClass: "pointer",
                on: { click: _vm.hideOtherUsersTasks },
              },
              [_vm._v("Hide Tasks assigned to other users.")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }